import { definePlugin } from '/@src/app'
import { useRealtimeStore } from '/@src/stores/realtime'
import { useRouteData } from '/@src/stores/routeData'
import { VueSignalR } from '@dreamonkey/vue-signalr'
import {
  HubConnectionBuilder,
  type IRetryPolicy,
  LogLevel,
  HttpTransportType,
} from '@microsoft/signalr'

// See https://docs.microsoft.com/en-us/javascript/api/@microsoft/signalr/hubconnectionbuilder
// See https://dev-ws.onvcp.org/js/chat.js

function retryPolicy(): IRetryPolicy {
  return {
    nextRetryDelayInMilliseconds: (retryContext) => {
      /*
      // SAMPLE retryContext
      console.log("retryContext", retryContext);
      {
          elapsedMilliseconds: 3384,
          previousRetryCount: 1,
          retryReason: Error {
              errorType: "FailedToNegotiateWithServerError",
              message: "Failed to complete negotiation with the server: TypeError: Failed to fetch",
              stack: "Error: Failed to complete negotiation with the server: TypeError: Failed to fetch\n    at ..."
          }
      }
      */
      if (retryContext.previousRetryCount === 0) return 1000
      if (retryContext.previousRetryCount === 1) return 2000
      if (retryContext.previousRetryCount === 2) return 3000
      if (retryContext.previousRetryCount === 3) return 4000
      return 5000
    },
  }
}

export default definePlugin(({ app, pinia }) => {
  const realtimeStore = useRealtimeStore(pinia)

  // only authenticated users should trigger WS connections
  const routeDataStore = useRouteData(pinia)
  const transportType = routeDataStore.routeData.isAuthenticated
    ? HttpTransportType.WebSockets | HttpTransportType.LongPolling
    : HttpTransportType.LongPolling

  const connection = new HubConnectionBuilder()
    .withUrl(`${import.meta.env.VITE_WS_BASE_URL}/hub`, {
      transport: transportType,
      withCredentials: true,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect(retryPolicy())
    .build()

  connection.on('Connected', async function (cid) {
    // console.log('cid', cid)
    await realtimeStore.setConnectionId(cid)
  })

  connection.on(
    'Status',
    function (/*status: { TotalConnections: number, Uptime: string }*/) {
      // console.log('status', status)
    }
  )

  connection.on('ReceiveMessage', function (payload) {
    console.log('ReceiveMessage :: ' + JSON.stringify(payload))
  })

  // See https://github.com/dreamonkey/vue-signalr
  app.use(VueSignalR, { connection })
})
