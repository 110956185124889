<script setup lang="ts">
// import { initDarkmode } from '/@src/stores/darkmode'
import { useRouteData } from '/@src/stores/routeData'
import { useChat } from '/@src/stores/chat'
import { useInterval, useDocumentVisibility } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useSignalR } from '@dreamonkey/vue-signalr'
// import { useRealtimeStore } from '/@src/stores/realtime'
// import { ChannelActivityService } from '/@src/assets/api-ws'

const signalr = useSignalR()

const route = useRoute()
const { currentPage, currentUser } = storeToRefs(useRouteData())

// This is the global app setup function

/**
 * Initialize the darkmode watcher
 *
 * @see /@src/stores/darkmode
 */
// initDarkmode()

const { counter /*, pause, resume, isActive */ } = useInterval(3000, { controls: true })
const visibility = useDocumentVisibility()

// watch(
//   () => {
//     return visibility.value
//   },
//   (v) => {
//     if (v === 'hidden') {
//       pause()
//     } else {
//       resume()
//     }
//   }
// )

watch(counter, () => {
  // send presence via WS (but not when the tab isn't active)
  if (!!currentUser.value?.id && visibility.value !== 'hidden')
    signalr.invoke('Present', currentUser.value.id)
})
// const realtimeStore = useRealtimeStore()
// watch(
//   () => {
//     return counter.value
//   },
//   async () => {
//     try {
//       console.log(isActive)
//       if (isActive) {
//         // const activityHeartbeatRequest:
//         await ChannelActivityService.postApiActivityHeartbeat({
//           requestBody: {
//             channels: realtimeStore.activeChannels,
//           },
//         })
//       }
//     } catch {}
//   }
// )
onMounted(() => {
  signalr.on('present', (id) => {
    const chatStore = useChat()
    chatStore.isPresent(id)
  })
})

onBeforeUnmount(() => {
  signalr.off('present')
})
</script>

<template>
  <!-- <div v-if="visibility !== 'hidden'"> -->
  <Suspense>
    <RouterView :key="currentPage?.id || route.path" v-slot="{ Component }">
      <Transition name="fade-slow" mode="out-in">
        <component :is="Component" />
      </Transition>
    </RouterView>
  </Suspense>
  <!-- <ClientOnly>
    <VReloadPrompt app-name="onVCP" />
  </ClientOnly> -->
  <!-- </div> -->
</template>
