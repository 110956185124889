import { acceptHMRUpdate, defineStore } from 'pinia'
import {
  CoreService,
  type Get_Chat_Messages_Using_From_And_ToDatasourceResponse,
} from '/@src/assets/api'
import { useRouteData } from '/@src/stores/routeData'
import { isAfter, subMinutes } from 'date-fns'
import { isString, orderBy } from 'lodash-es'
import { usePanels } from './panels'

export interface IChatMember {
  id: string
  avatar: string | null
  last_name: string | null
  first_name: string | null
  title: string | null
  role: string | null
  lastMessageDate?: Date | null
  lastMessage?: string | null
  presence?: 'online' | 'away' | 'offline'
  lastStatusDate?: Date | null
}

export interface IChatGroup {
  id: string
  name: string
  member_count: number
  date_created: string
  lastMessageDate?: Date | null
  lastMessage?: string | null
}

export interface IChatConversation {
  id: string
  avatar: string | null
  name: string
  member_count: number
  lastMessageDate?: Date
  lastMessage?: string
  presence?: 'online' | 'away' | 'offline'
}

export interface IChatNotificationTracker {
  unreadMessages: Array<{
    from: string
    to: string
    ids: Array<string>
  }>
}

export const useChat = defineStore('chat', () => {
  const members = useSessionStorage<IChatMember[]>('chat-members', [])
  const groups = useSessionStorage<IChatGroup[]>('chat-groups', [])
  const tenant_id = useSessionStorage<string | null>('chat-tenant-id', null)
  const initiative_id = useSessionStorage<string | null>('chat-initiative-id', null)
  const app_id = useSessionStorage<string | null>('chat-app-id', null)
  const last_fetch = useSessionStorage<Date>('chat-last-fetch', new Date())
  const notificationTracker = useSessionStorage<IChatNotificationTracker>(
    'notification-tracker',
    { unreadMessages: [] }
  )

  const panels = usePanels()

  const chatMembers = computed(() => {
    return members.value.map((m) => {
      if (typeof m.lastStatusDate === 'string')
        m.lastStatusDate = new Date(m.lastStatusDate)
      const isAway =
        m.lastStatusDate && isAfter(m.lastStatusDate, subMinutes(new Date(), 15))
      const isOnline =
        m.lastStatusDate &&
        isAway &&
        isAfter(m.lastStatusDate, subMinutes(new Date(), 15))
      const presence = isOnline ? 'online' : isAway ? 'away' : 'offline'
      return {
        ...m,
        presence,
      }
    })
  })

  const chatGroups = computed(() => {
    return groups.value
  })

  const chatConversations = computed<IChatConversation[]>(() => {
    return orderBy(
      [
        ...chatMembers.value
          .filter((cm) => !!cm.lastMessageDate)
          .map((cm) => {
            // ...chatMembers.value.map(cm => {
            return {
              ...cm,
              name: `${cm.last_name || ''}, ${cm.first_name || ''}`,
              member_count: 1,
              lastMessageDate:
                cm.lastMessageDate && isString(cm.lastMessageDate)
                  ? new Date(cm.lastMessageDate)
                  : undefined,
            } as IChatConversation
          }),
        ...chatGroups.value.map((cm) => {
          return {
            ...cm,
            avatar: null,
            presence: undefined,
            lastMessageDate:
              cm.lastMessageDate && isString(cm.lastMessageDate)
                ? new Date(cm.lastMessageDate)
                : new Date(cm.date_created),
          } as IChatConversation
        }),
      ],
      ['lastMessageDate'],
      'desc'
    ).slice(0, 100)
  })

  async function isPresent(id: string) {
    const member = members.value.find((cm) => cm.id === id)
    if (member) {
      member.presence = 'online'
      member.lastStatusDate = new Date()
    }
  }

  async function loadMembersAndGroups(refresh: boolean) {
    const routeData = useRouteData()
    const { currentTenant, currentInitiative, currentApp } = routeData

    const sameRoute =
      tenant_id.value === currentTenant?.id ||
      (null && initiative_id.value === currentInitiative?.id) ||
      (null && app_id.value === currentApp?.id) ||
      null

    if (!refresh && sameRoute) {
      if (last_fetch) {
        if (isAfter(last_fetch.value, subMinutes(new Date(), 5))) return
      }
    }

    // fetch users
    tenant_id.value = currentTenant?.id || null
    initiative_id.value = currentInitiative?.id || null
    app_id.value = currentApp?.id || null

    try {
      const res = await CoreService.getChatMembersGroupsandConversations({
        tenantId: tenant_id.value || '',
        requestBody: {
          tenant_id: tenant_id.value,
          initiative_id: initiative_id.value,
          app_id: app_id.value,
        },
      })

      const chatMemberResults = (
        res.results && res.results.length > 0
          ? res.results[0].map((r) => {
              return {
                ...r,
                presence: 'offline',
              }
            })
          : []
      ) as IChatMember[]

      groups.value = (
        res.results && res.results.length > 1 ? res.results[1] : []
      ) as IChatGroup[]

      members.value = chatMemberResults
      last_fetch.value = new Date()
    } catch {}
  }

  const addMessageToUnreadMessageTracker = (
    message: Get_Chat_Messages_Using_From_And_ToDatasourceResponse,
    userId: string
  ) => {
    // console.log('adding chat message', message, userId)
    const isActiveChatWithId =
      panels.data.tabState === 'chat' && panels.data.chatWithId === message.to_id
    if (message.from_id === userId || isActiveChatWithId) return

    const tracker = notificationTracker.value.unreadMessages.find(
      (x) => x.from === message.from_id && x.to === message.to_id
    )
    if (
      message.message_id &&
      tracker?.ids &&
      !tracker?.ids.includes(message.message_id)
    ) {
      tracker.ids.push(message.message_id)
      // console.log('added to tracker')
    } else if (message.from_id && message.to_id && message.message_id) {
      notificationTracker.value.unreadMessages.push({
        from: message.from_id,
        to: message.to_id,
        ids: [message.message_id],
      })
      // console.log('added new tracker')
    }
    // console.log('tracker', notificationTracker.value, panels.data)
  }

  const removeMessageFromUnreadMessageTracker = (
    message: Get_Chat_Messages_Using_From_And_ToDatasourceResponse,
    userId: string
  ) => {
    // console.log('removing chat message', message, userId)
    if (message.from_id === userId) return

    const tracker = notificationTracker.value.unreadMessages.find(
      (x) => x.from === message.from_id && x.to === message.to_id
    )
    if (message.message_id && tracker?.ids && tracker?.ids.includes(message.message_id)) {
      tracker.ids = tracker.ids.filter((x) => x !== message.message_id)
      // console.log('removed from tracker')
    }
    // console.log('tracker', notificationTracker.value, panels.data)
  }

  const removeMessagesFromUnreadMessageTracker = (
    from: string,
    to: string,
    userId: string
  ) => {
    // console.log('removing chat messages', from, to, userId)
    const trackers = notificationTracker.value.unreadMessages.filter(
      (x) =>
        ((from || '') !== userId && x.from === from && x.to === to) ||
        ((to || '') !== userId && x.to === from && x.from === to) ||
        (from === '' && x.to === to)
    )
    trackers.forEach((tracker) => {
      if (tracker?.ids) {
        tracker.ids = []
        // console.log('removed all from tracker')
      }
    })
    // console.log('tracker', notificationTracker.value, panels.data)
  }

  const totalUnreadMessagesCount = computed(() => {
    return notificationTracker.value.unreadMessages
      .map((x) => x.ids.length)
      .reduce((prev, current) => {
        return prev + current
      }, 0)
  })

  const totalUnreadMessagesFromToCount = (from: string, to: string) => {
    return notificationTracker.value.unreadMessages
      .filter((x) => (x.from === to && x.to === from) || (from === '' && x.to === to))
      .map((x) => x.ids.length)
      .reduce((x, y) => x + y, 0)
  }

  return {
    chatMembers,
    chatGroups,
    chatConversations,
    loadMembersAndGroups,
    isPresent,
    addMessageToUnreadMessageTracker,
    removeMessageFromUnreadMessageTracker,
    removeMessagesFromUnreadMessageTracker,
    totalUnreadMessagesCount,
    totalUnreadMessagesFromToCount,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChat, import.meta.hot))
}
