import { Quasar, Notify } from 'quasar'
import { definePlugin } from '/@src/app'

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'

// Using index.html instead (issues with compiling this with latest version of `sass` compiler)
// Import Quasar css
// import 'quasar/src/css/index.sass'

export default definePlugin(({ app }) => {
  app.use(Quasar, {
    plugins: {
      Notify
    }, // import Quasar plugins and add here
    config: {
      brand: {
        // primary: '#006699',
        // secondary: '#8b7355',
        // accent: '#926bb3',
        // positive: '#32673a',
        // negative: '#921620',
        // info: '#94d2ff',
        // warning: '#d48735',
        // dark: '#3a4755',
        // darkPage: '#121212',
      },
      notify: {}, // default set of options for Notify Quasar plugin
      loading: {}, // default set of options for Loading Quasar plugin
      loadingBar: {}, // settings for LoadingBar Quasar plugin
      // ..and many more (check Installation card on each Quasar component/directive/plugin)
    },
  })
})
