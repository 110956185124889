import { _HasDataLoaderMeta, _mergeRouteRecord } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('C:/2023-rtd-3/rtd-vuero/src/pages/[...all].vue'),
    /* no props */
    /* no children */
  },
  {
    path: '/missing-tenant',
    name: '/missing-tenant',
    component: () => import('C:/2023-rtd-3/rtd-vuero/src/pages/missing-tenant.vue'),
    /* no props */
    /* no children */
  }
]
