import { definePlugin } from '/@src/app'
import { useOnline } from '@vueuse/core'
import { useRouteData } from '/@src/stores/routeData'
// import { CoreService } from '/@src/assets/api'

export default definePlugin(async ({ router, pinia }) => {
  const routeDataStore = useRouteData(pinia)
  const online = useOnline()

  if (online.value) {
    try {
      await routeDataStore.loadRouteData(null, true)
    } catch (err) {
      console.log(err)
    }
  }

  router.beforeEach(async (to) => {
    if (online.value && !routeDataStore.loading) {
      try {
        const routePath = `/${to.fullPath.length > 0 ? to.fullPath.substring(1) : ''}`
        await routeDataStore.loadRouteData(
          routePath,
          false /* route may have changed inside the same app, only force reload if apps changed */
        )
      } catch (err) {
        console.log(err)
      }
    }
  })
})
