import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useStorage } from '@vueuse/core'
import { type IVcpContext } from '/@src/assets/api'

export const useUserSession = defineStore('userSession', () => {
  // token will be synced with local storage
  // @see https://vueuse.org/core/usestorage/
  const session = useStorage<Partial<IVcpContext>>('session', {})

  const loading = ref(true)

  function loginUri(relativePath: string) {
    const href = `${window.location.origin}${relativePath || window.location.pathname}`
    return `${session.value?.loginUri || ''}?redirect_uri=${encodeURI(href)}`
  }
  function logoutUri(relativePath: string) {
    const href = `${window.location.origin}${relativePath || window.location.pathname}`
    return `${session.value?.logoutUri || ''}?redirect_uri=${encodeURI(href)}`
  }
  function viewProfileUri(relativePath: string) {
    const href = `${window.location.origin}${relativePath || window.location.pathname}`
    return `https://auth.onvcp.org/realms/vcp/account/?referrer=onVCP&referrer_uri=${encodeURI(href)}`
  }
  const user = computed(() => session.value?.user || null)
  const isAuthenticated = computed(() => session.value?.isAuthenticated || false)

  function setSession(newSession: IVcpContext) {
    session.value = newSession
  }

  function setLoading(newLoading: boolean) {
    loading.value = newLoading
  }

  return {
    loginUri,
    logoutUri,
    viewProfileUri,
    user,
    isAuthenticated,
    loading,
    setSession,
    setLoading,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
