import axios, { type AxiosInstance } from 'axios'
import { OpenAPI } from '/@src/assets/api'
import { OpenAPI as OpenAPIWS } from '/@src/assets/api-ws'
import { useRouteData } from '/@src/stores/routeData'
// import { addRequestInterceptor } from '/@src/assets/api/core/request'

let api: AxiosInstance

export function createApi() {
  // Here we set the base URL for all requests made to the apis

  OpenAPI.BASE = import.meta.env.VITE_API_BASE_URL
  OpenAPI.WITH_CREDENTIALS = true
  OpenAPI.CREDENTIALS = 'include'
  OpenAPI.HEADERS = async () => {
    const headers: Record<string, string> = {}
    const { xDomain, xTenant } = useRouteData()
    if (xDomain && xDomain.length) headers['x-domain'] = xDomain
    if (xTenant && xTenant.length) headers['x-tenant'] = xTenant
    return headers
  }

  // the realtime endpoint is:
  // - dev-ws.onvcp.org (instead of dev-api.onvcp.org), or
  // - ws.onvcp.org (instead of api.onvcp.org)
  OpenAPIWS.BASE = import.meta.env.VITE_WS_BASE_URL

  // addRequestInterceptor((req) => {
  //   if (!req.headers) {
  //     req.headers = new Headers()
  //   }

  //   const { xDomain, xTenant } = useRouteData();
  //   if (xDomain) {
  //     (req.headers as Headers).set('x-domain', xDomain)
  //   }
  //   if (xTenant) {
  //     (req.headers as Headers).set('x-tenant', xTenant)
  //   }
  // })

  api = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
  })

  // We set an interceptor for each request to
  // include Bearer token to the request if user is logged in
  api.interceptors.request.use((config) => {
    const { xDomain, xTenant } = useRouteData()

    if (xDomain && xDomain.length > 0) {
      config.headers = {
        ...config.headers,
        ['x-domain']: xDomain,
      }
    }

    if (xTenant && xTenant.length > 0) {
      config.headers = {
        ...config.headers,
        ['x-tenant']: xTenant,
      }
    }

    return config
  })

  return api
}

export function useApi() {
  if (!api) {
    createApi()
  }
  return api
}
