/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IVcpContext } from '../models/IVcpContext';
import type { LoginCredentialsRequest } from '../models/LoginCredentialsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Profile information for the current authenticated user.
     * @returns IVcpContext Success
     * @throws ApiError
     */
    public static getApiAuthMe(): CancelablePromise<IVcpContext> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/me',
        });
    }

    /**
     * Retrieve a JWT bearer auth token using username and password.
     * @returns any Success
     * @throws ApiError
     */
    public static postApiAuthLogin({
requestBody,
}: {
requestBody?: LoginCredentialsRequest,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Invalidate the JWT bearer auth token.
     * @returns any Success
     * @throws ApiError
     */
    public static postApiAuthLogout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/logout',
        });
    }

    /**
     * OAuth2 login endpoint redirect (BROWSER ONLY!!)
     * @returns any Success
     * @throws ApiError
     */
    public static getAuthLogin({
redirectUri,
}: {
redirectUri?: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/login',
            query: {
                'redirect_uri': redirectUri,
            },
        });
    }

    /**
     * OAuth2 logout endpoint redirect (BROWSER ONLY!!)
     * @returns any Success
     * @throws ApiError
     */
    public static getAuthLogout({
redirectUri,
}: {
redirectUri?: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/logout',
            query: {
                'redirect_uri': redirectUri,
            },
        });
    }

}
