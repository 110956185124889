import { definePlugin } from '/@src/app'
import { useUserSession } from '/@src/stores/userSession'
import { useOnline } from '@vueuse/core'
import { AuthService } from '/@src/assets/api'

/**
 * Here we are setting up two router navigation guards
 * (note that we can have multiple guards in multiple plugins)
 *
 * We can add meta to pages either by declaring them manualy in the
 * routes declaration (see /@src/router.ts)
 * or by adding a <route> tag into .vue files (see /@src/pages/sidebar/dashboards.ts)
 *
 * <route lang="yaml">
 * meta:
 *   requiresAuth: true
 * </route>
 *
 * <script setup lang="ts">
 *  // TS script
 * </script>
 *
 * <template>
 *  // HTML content
 * </template>
 */
export default definePlugin(async ({ router, pinia }) => {
  const userSession = useUserSession(pinia)
  const online = useOnline()

  if (online.value) {
    try {
      userSession.setLoading(true)
      const session = await AuthService.getApiAuthMe()
      userSession.setSession(session)
    } catch (err) {
      console.log(err)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  router.beforeEach(async (to) => {
    if (online.value && !userSession.loading) {
      try {
        const session = await AuthService.getApiAuthMe()
        userSession.setSession(session)
      } catch (err) {
        console.log(err)
      }
    }

    userSession.setLoading(false)

    // can return a different destination to 'cause a redirect
    // if (to.meta.requiresAuth && !userSession.isLoggedIn) {
    //   return {
    //     name: '/auth/login',
    //     // save the location we were at to come back later
    //     query: { redirect: to.fullPath },
    //   }
    // }
  })
})
